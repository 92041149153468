import Image from "next/image";
import React, { useRef, useState } from "react";
import UseAnimationIos from "@/pages/api/UseAnimationIos";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper";
//import styles from "../styles/slider.module.css";
import { GilroyFontDark, GilroyFontLight } from "@lib/GilroyFont";
import { useMediaQuery } from "@mui/material";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const OurPartners = ({ data, width, paddingL }) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef();

  const {categories} = data;

  {
    /* eslint-disable-next-line */
  }
  const [isScreen, setOnScreen] = useState(false);
  const ref = useRef();
  UseAnimationIos(ref, setOnScreen);

  const isMobile = useMediaQuery("(max-width:786px)");

  return (
    <div
      className={` partners-home-container-child ${width ? width : "max-w-[80rem]"}  m-auto py-2 
       ${paddingL ? paddingL : "pl-mobile"}  ${isMobile ? "" : "pl-mobile"}   `}
      ref={ref}
    >
      <style jsx>
        {`
          .partners-home-container-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
            width: 100%;
            height: calc(100% - 20%);
          }
        `}
      </style>
      <h3
        className={` heading-text ${GilroyFontDark.className}  text-[30px] heading-text mb-3 text-start text-black `}
      >
        {data?.partners_slider_heading
          ? data?.partners_slider_heading
          : "Our Partners"}
      </h3>
      <p
        className={`${GilroyFontLight.className}  p-home-black   pb-10 sm:pb-2 `}
      >
        {data?.partners_slider_paragraph
          ? data?.partners_slider_paragraph
          : `Collaborative Digital Marketing: Fueling Success. The deep connections we've forged with our partners are what drive our journey. We advance businesses utilizing data-driven digital marketing strategies because we are united by a common vision. Together, we make sense of the constantly changing environment and develop creative solutions that produce outstanding outcomes. Success stories of our partners serve as evidence of our commitment to their development. With each partnership, we reinforce our dedication to turning obstacles into opportunities and influencing the direction of digital marketing.`}
      </p>

      <Swiper
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        injectStyles={[
          ` .swiper-button-next,
          .swiper-button-prev {
            background-color: white;
            background-position: center;
            background-size: 40px;
            background-repeat: no-repeat;
            padding: 8px 16px;
            border-radius: 100%;
            border: 2px solid black;
            color: red;
          }

          .swiper-button-prev {
            background-image: url("https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/3597ca3c-82a4-4dff-b571-83e7f7e9ab00/mobilehero");
          }

          .swiper-button-next {
            background-image: url("https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/3597ca3c-82a4-4dff-b571-83e7f7e9ab00/mobilehero");
          }

          .swiper-button-next::after,
          .swiper-button-prev::after {
            content: "";
          }       
      `,
        ]}
        className="relative w-[100%]"
        spaceBetween={0}
        slidesPerView={3}
        slidesPerGroup={1}
        breakpoints={{
          640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 6,
          },
          1120: {
            slidesPerView: 6,
          },
          2000: {
            slidesPerView: 6,
          },
        }}
      >
        {!data?.partners_images
          ? categories?.map((category, index) => (
              <SwiperSlide key={index}>
                <Image
                  priority={false}
                  placeholder="blur"
                  blurDataURL={category.image}
                  height={145}
                  width={145}
                  src={category.image}
                  alt={category.alt}
                />
              </SwiperSlide>
            ))
          : data?.partners_images?.map((category, index) => (
              <SwiperSlide key={index}>
                <Image
                  priority={false}
                  placeholder="blur"
                  blurDataURL={category.partners_image}
                  height={145}
                  width={145}
                  src={category.partners_image}
                  alt={category.alt}
                />
              </SwiperSlide>
            ))}
        {/* <button
            className={styles["swiper-button-prev"]}
            onClick={() => swiperRef.current?.slidePrev()}
            aria-label="Previous slide"
          >  </button>
          <button
            className={styles["swiper-button-next"]}
            onClick={() => swiperRef.current?.slideNext()}
            aria-label="Next slide"
          >  </button> */}
      </Swiper>
    </div>
  );
};

export default OurPartners;
