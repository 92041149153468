import styles from "./index.module.css";
import { Grid } from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import { useInView } from "react-intersection-observer";
import { AnimatePresence } from "framer-motion";
import { GilroyFontDark, GilroyFontLight } from "@lib/GilroyFont";
const PortfolioSectionHomePage = ({ data }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This means the animation will trigger only once when it comes into view
    threshold: 0.1, // Percentage of the component's visibility, adjust as needed
  });

  const { office_images, office_video } = data;

  return (
    <div ref={ref}>
      <div>
        <div
          className={` ${styles.portfolioSection} max-w-[80rem] mx-auto pl-mobile`}
        >
          <h3
            className={`heading-text ${GilroyFontDark.className} text-[30px] ${styles.heading}  `}
          >
            {" "}
            {data.homePortfolio_heading}{" "}
          </h3>
          <p
            className={`${styles.paragraph} ${GilroyFontLight.className} p-home`}
          >
            {data.homePortfolio_paragraph}
          </p>
          <AnimatePresence>
            {inView && (
              <div className="flex justify-center relative mr-[2.25rem]  sm:ml-0">
                <Grid container marginTop={2}>
                  <Grid item xs={4} lg={4}>
                    <Image
                      placeholder="blur"
                      blurDataURL={
                        office_images[0]
                          ? office_images[0].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/a1ef949e-be6f-433c-0cd0-8a5c66c75800/1920x1080"
                      }
                      width={600}
                      height={600}
                      priority="true"
                      className="p-1"
                      alt={office_images[0].alt}
                      src={
                        office_images[0]
                          ? office_images[0].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/a1ef949e-be6f-433c-0cd0-8a5c66c75800/1920x1080"
                      }
                    />
                    <Image
                      placeholder="blur"
                      blurDataURL={
                        office_images[0]
                          ? office_images[1].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/f5cfcdd1-14f4-4267-f570-962b40416300/1920x1080"
                      }
                      width={600}
                      height={600}
                      priority="true"
                      className="p-1"
                      alt={office_images[1].alt}
                      src={
                        office_images[0]
                          ? office_images[1].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/f5cfcdd1-14f4-4267-f570-962b40416300/1920x1080"
                      }
                    />
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <Image
                      placeholder="blur"
                      blurDataURL={
                        office_images[0]
                          ? office_images[2].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g"
                      }
                      width={600}
                      height={600}
                      priority="true"
                      className="p-1"
                      alt={office_images[2].alt}
                      src={
                        office_images[0]
                          ? office_images[2].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g"
                      }
                    />

                    <Image
                      placeholder="blur"
                      blurDataURL={
                        office_images[0]
                          ? office_images[3].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g"
                      }
                      width={600}
                      height={600}
                      priority="true"
                      className="p-1"
                      alt={office_images[3].alt}
                      src={
                        office_images[0]
                          ? office_images[3].home_portfolio_image
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g"
                      }
                    />
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <video
                      preload="auto"
                      poster={
                        office_video
                          ? office_video[0].place_holder
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/0ff9a63d-8613-4017-c9da-c220b135c600/1920x1080"
                      }
                      className="p-1"
                      autoPlay
                      loop
                      playsInline
                      muted
                      alt=""
                      src={
                        office_video
                          ? office_video[0].home_portfolio_video
                          : "https://customer-xsqikd9wak0sq9wp.cloudflarestream.com/e5ca4d536de03fb151ac689beb95714a/downloads/default.mp4"
                      }
                    />

                    <video
                      preload="auto"
                      poster={
                        office_video
                          ? office_video[1].place_holder
                          : "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/a10e023a-82e8-4879-a363-ef5944262800/1920x1080"
                      }
                      className="p-1"
                      autoPlay
                      loop
                      playsInline
                      muted
                      alt=""
                      src={
                        office_video
                          ? office_video[1].home_portfolio_video
                          : "https://customer-xsqikd9wak0sq9wp.cloudflarestream.com/bef38dc4d99b68445f42888b8164d1ca/downloads/default.mp4"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/portfolio"
                    >
                      <div className="flex justify-end  mx-5 mt-2 text-[3vh] sm:text-[2.5vw]">
                        <div className="pr-2">View all</div>
                        <Image
                          width={200}
                          height={200}
                          className="h-8 w-8 mt-2 sm:mt-4 sm:h-10 sm:w-10"
                          alt="vector up arrow"
                          src="/assets/portfolio-home/vector.svg"
                        />
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            )}
          </AnimatePresence>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSectionHomePage;
