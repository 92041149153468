import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { useMediaQuery } from "@mui/material";
import styles from "@/styles/transformbrand.module.css";

import { GilroyFontDark } from "@lib/GilroyFont";

SwiperCore.use([Pagination]);

const TransformBrand = ({ data }) => {
  const categories = [
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/ffae8afc-a9c8-400f-7b30-1e75f5063200/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a logo.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/08424a51-569a-4c5d-045d-443d3124b000/1920x1080",
      alt: "Row of computer monitors on a table in an office setting.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/abe64ca2-718b-4477-2ce5-e7708ae44e00/1920x1080",
      alt: "Office room with tables, chairs, laptops, and a whiteboard.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/85e83696-8e7f-4fc8-7f69-6a8764bf4e00/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/c2d6da07-0fdb-4591-d0cb-97a0e6050500/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/32c7d7d6-ff46-4596-4405-6b2cd8857300/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/9de78d3e-8d0f-4031-5a92-4eabd99b5400/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/6a408429-1727-4ab6-fa46-9b8880cee500/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/7293c73d-8bf1-41b0-69c1-3f1ed1578700/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/ad1a517f-c94f-4ddd-9e5b-8b7d31bbbe00/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
    {
      image:
        "https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/3893e877-877c-4e06-bbfa-08cbc2bf9c00/1920x1080",
      alt: "Person holding a color palette in front of a computer, possibly designing a character.",
    },
  ];

  const isMobile = useMediaQuery("(max-width:767px)"); // Set breakpoint for mobile

  const { office_images } = data;
  return (
    <div
      className={`w-auto  ${
        isMobile ? "" : ""
      }  max-w-[80rem] m-auto rounded-xl py-[2rem] pl-mobile sm:mt-[-2px]`}
    >
      <div className="flex flex-col justify-start  "></div>
      <div className="flex flex-col justify-start  ">
        <h2
          className={`heading-text text-[30px] text-black ${GilroyFontDark.className} `}
          dangerouslySetInnerHTML={{ __html: data?.office_slider_heading }}
        ></h2>
        <p
          className={` ${styles["paragraph-text-transformbrand"]} py-2 p-home-black `}
          dangerouslySetInnerHTML={{ __html: data?.office_slider_paragraph }}
        ></p>
      </div>
      <div className="relative ml-[-20px] sm:ml-0">
        <Swiper
          style={{
            "--swiper-pagination-color": "#FFBA08",
            "--swiper-pagination-bullet-inactive-color": "transparent",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "30px",
            "--swiper-pagination-bullet-horizontal-gap": "1px",
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          spaceBetween={-20}
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' +
                className +
                '"></span>'
              );
            },
          }}
          breakpoints={{
            640: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 3,
            },
            1120: {
              slidesPerView: 3,
            },
            2000: {
              slidesPerView: 3,
            },
          }}
        >
          {!office_images
            ? categories?.map((category, index) => (
                <SwiperSlide key={index}>
                  <div className="max-h-[20rem] min-h-[20rem] px-5">
                    <Image
                      height={500}
                      width={500}
                      src={category.image}
                      alt={category.alt}
                      blurDataURL={category.image}
                      placeholder="blur"
                    />
                  </div>
                </SwiperSlide>
              ))
            : office_images?.map((category, index) => (
                <SwiperSlide key={index}>
                  {isMobile ? (
                    <div className="max-h-[400px] max-w-[800px] min-h-[20rem] px-5">
                      <Image
                        height={500}
                        width={500}
                        src={category.image_office_slider_mobile}
                        alt={category.alt}
                        blurDataURL={category.image_office_slider_mobile}
                        placeholder="blur"
                      />
                    </div>
                  ) : (
                    <div className="max-h-[20rem] min-h-[20rem] px-5">
                      <Image
                        height={500}
                        width={500}
                        src={category.image_office_slider}
                        alt={category.alt}
                        blurDataURL={category.image_office_slider}
                        placeholder="blur"
                      />
                    </div>
                  )}
                </SwiperSlide>
              ))}

          <div className="flex  justify-center swiper-pagination-custom" />
        </Swiper>

        <style jsx global>
          {`
            .swiper-pagination-bullet > img {
              content: url("https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/f33f87d2-4f6e-41ca-3e33-20f3a26e4000/mobilehero");
              width: 30px;
              height: 30px;
            }

            .swiper-pagination-bullet-active > img {
              content: url("https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/58ee2fe5-cd9e-43d0-6878-89047402a100/mobilehero");
              width: 33px;
              height: 30px;
            }

            .pagination-bullet {
              width: 4.5rem;
              margin: 0 0.1rem;
            }

            span.swiper-pagination-bullet.swiper-pagination-bullet-active {
              background-color: transparent;
              stroke: rgba(255, 255, 255, 0.904); /* Border color */
              stroke-width: 20; /* 5 units width for the border */
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default TransformBrand;
