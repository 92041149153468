import React from "react";
import dynamic from "next/dynamic";

const DesktopServices = dynamic(() => import("./DesktopServices"), {
  ssr: true,
});
const MobileServices = dynamic(() => import("./MobileServices"), { ssr: true });

import { useMediaQuery } from "@mui/material";

const ServicesC = ({ serviceData }) => {
  const isMobile = useMediaQuery("(max-width:786px)");
  return (
    <>
      {isMobile ? (
        <MobileServices serviceData={serviceData} />
      ) : (
        <>
          {" "}
          <DesktopServices serviceData={serviceData} />
          {/* <HeadLine /> */}
        </>
      )}{" "}
    </>
  );
};

export default ServicesC;
