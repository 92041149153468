import { GilroyFontDark } from "@lib/GilroyFont";

const FaqDynamic = ({ data, faq }) => {
  return (
    <div className="pt-[5rem] pb-[5rem] min-h-screen px-[1rem] max-w-[65rem] sm:m-auto rounded-2xl text-black">
      <div className="faq-content">
        <style jsx global>{`
          .faq-content {
            margin: 0 auto;
            background-color: white;
            padding: 2rem;
            max-width: 60rem;
            margin-bottom: 5rem;
            border-radius: 50px;
            color: black;
          }

          .faq-question {
            padding: 20px 0;
            border-bottom: 1px dotted #ccc;
          }

          .panel-title {
            font-size: 24px;
            width: 100%;
            position: relative;
            margin: 0;
            padding: 10px 10px 0 48px;
            display: block;
            cursor: pointer;
          }

          @media screen and (max-width: 768px) {
            .panel-title {
              font-size: 24px;
              width: 100%;
              position: relative;
              margin: 0;
              padding: 0px;
              display: block;
              cursor: pointer;
            }
          }

          .panel-content {
            font-size: 20px;
            padding: 0px 0px;
            margin: 0 5px;
            height: 0;
            overflow: hidden;
            z-index: 10;
            position: relative;
            opacity: 0;
            transition: 0.4s ease;
            color: black !important;
          }

          .panel:checked ~ .panel-content {
            height: auto;
            opacity: 1;
            padding-top: 10px;
          }

          @media screen and (min-width: 768px) {
            .panel:checked ~ .panel-content {
              height: auto;
              opacity: 1;
              padding-left: 64px;
              padding-top: 10px;
            }
          }

          .plus {
            position: absolute;
            margin-left: 20px;
            margin-top: 4px;
            z-index: 5;
            font-size: 42px;
            line-height: 100%;
            -webkit-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            transition: 0.2s ease;
          }

          .panel:checked ~ .plus {
            transform: rotate(45deg);
          }

          .panel {
            display: none;
          }
        `}</style>

        <h2
          className={` ${GilroyFontDark.className} sm:text-center heading-text text-black `}
        >
          {" "}
          {faq?.faq_heading ? faq?.faq_heading : "FAQS"}{" "}
          <strong className="text-[#443275ff]"> . </strong>{" "}
        </h2>

        {!faq
          ? data?.map((faq, index) => (
              <div key={index} className="faq-question">
                <input id={faq.indexNumber} type="checkbox" className="panel" />
                {/* <div    className="plus">+</div> */}
                <label htmlFor={faq.indexNumber} className="panel-title">
                  {" "}
                  <h2 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}> + {faq.question}</h2>{" "}
                </label>
                <p className="panel-content"> {faq.answer} </p>
              </div>
            ))
          : faq?.faq_items.map((faq, index) => (
              <div key={`q${index}`} className="faq-question">
                <input id={`q${index}`} type="checkbox" className="panel" />
                {/* <div    className="plus">+</div> */}
                <label htmlFor={`q${index}`} className="panel-title">
                  {" "}
                  <h2 style={{ fontWeight: "600", fontSize: "20px", color: "black" }}> + {faq.question}</h2>{" "}
                </label>
                <p className="panel-content"> {faq.answer} </p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default FaqDynamic;
